import React from "react";
import "./Modal.css";

import Uploader from "../uploader/Uploader";
const Modal = () => {
  return (
    <>
      <div className="container">
        <div
          style={{
            backgroundColor: "white",
            marginTop: "10px",
            borderRadius: "30px",
            boxShadow: "15px 15px 19px rgba(0, 0, 0, 0.1)",
            padding: "20px 20px 40px 20px",
            width: "clamp(400px, 60%, 1000px)",
            marginBottom: "50px",
          }}
        >
          <div className="bannerContainer">
            <img
              src="/assets/teldio_logo.png"
              alt="Teldio Logo"
              className="logo"
            />
          </div>

          <Uploader />
        </div>
      </div>
    </>
  );
};

export default Modal;
