import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import "./DataTable.css";

function DataTable({ data }) {
  return (
    <>
      <TableContainer
        component={Paper}
        className="table"
        sx={{ marginBottom: "10px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="head">ID</TableCell>
              <TableCell className="head">Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow
                key={item.id + index}
                className={item.existing ? "existed" : "new"}
              >
                <TableCell className="body">{item.id}</TableCell>
                <TableCell className="body">{item.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" spacing={1} sx={{ marginBottom: "10px" }}>
        <Chip
          label="Existing From File"
          sx={{ backgroundColor: "lightyellow" }}
        />
        <Chip label="New" sx={{ backgroundColor: "#e8ffe8" }} />
      </Stack>
    </>
  );
}

export default DataTable;
