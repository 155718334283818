import React from "react";
import { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import DataTable from "../DataTable";
import Button from "@mui/material/Button/Button";
import "./Uploader.css";
import TextField from "@mui/material/TextField/TextField";
import styled from "@emotion/styled";
import Spreadsheet from "react-spreadsheet";
import ContextMenu from "./ContextMenu";

const BigButton = styled(Button)(() => ({
  width: "100%",
  height: "40px",
  borderRadius: "50px",
  backgroundColor: "#A0AD39",
  "&:hover": {
    backgroundColor: "#c1cd66",
  },
  fontSize: "17px",
  color: "white",
}));

const Uploader = () => {
  const [file, setFile] = useState();
  const [data, setData] = useState();
  const [newLinesString, setNewLinesString] = useState();
  const [newLinesArray, setNewLinesArray] = useState({});
  const [allLines, setAllLines] = useState();
  const [stage, setStage] = useState(0);
  const [items, setItems] = useState([
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],
  ]); // Initialize with two blank rows
  const [unsavedRows, setUnsavedRows] = useState(new Set());
  const [contextMenu, setContextMenu] = useState(null);

  const [defaults, setDefaults] = useState({});
  const displayedColumns = ["ID", "Name"];
  const displayedData = items;

  const handleChange = (file) => {
    setFile(file);
    console.log(file);
    processTextFile(file);
  };

  const handleChangeSpreadsheet = (newItems) => {
    console.log(newItems);
    const updatedItems = newItems.map((row) => {
      if (!row || row.length === 0) {
        return null;
      }
      return [{ value: row[0]?.value || "" }, { value: row[1]?.value || "" }];
    });

    // Add a new row if the last row is not empty
    const lastRow = updatedItems[updatedItems.length - 1];
    if (lastRow.some((cell) => cell.value !== "")) {
      updatedItems.push([{ value: "" }, { value: "" }]);
    }

    setItems(updatedItems);

    const updatedUnsavedRows = new Set(unsavedRows);
    updatedItems.forEach((row, index) => {
      if (row.some((cell) => cell.value !== "")) {
        updatedUnsavedRows.add(index);
      }
    });
    setUnsavedRows(updatedUnsavedRows);
  };

  function handleReset() {
    setFile(null);
    setData(null);
    setNewLinesArray({});
    setNewLinesString(null);
    setAllLines(null);
    setStage(0);
    setItems([
      [{ value: "" }, { value: "" }],
      [{ value: "" }, { value: "" }],
      [{ value: "" }, { value: "" }],
      [{ value: "" }, { value: "" }],
      [{ value: "" }, { value: "" }],
      [{ value: "" }, { value: "" }],
    ]);
  }

  function processTextFile(file) {
    const reader = new FileReader();
    const deviceLines = [];

    reader.onload = function (event) {
      const fileContent = event.target.result;
      const lines = fileContent.split("\n");

      for (let line of lines) {
        deviceLines.push(line);
      }

      setData(deviceLines.filter((line) => line.includes("DEVICE")));
      setAllLines(deviceLines.filter((line) => !line.includes("DEVICE")));
      console.log("deviceLines", deviceLines); // Output the device lines
    };

    reader.readAsText(file);
  }

  function extractDataFromExisting() {
    if (data.length === 0) {
      return [];
    }

    const newArray = [];
    for (let i = 0; i < data.length; i++) {
      let line = data[i].split("=")[1];
      let lineArr = line.split("***");
      console.log("lineArr", lineArr);
      const obj = {
        id: lineArr[0],
        radio_type: lineArr[1],
        name: lineArr[2],
        tracking: lineArr[3],
        fleetid: lineArr[4],
        updateratemethod: lineArr[5],
        customrate: lineArr[6],
        type: lineArr[9],
        existing: true,
      };
      newArray.push(obj);
    }
    setDefaults(newArray[0]);
    return newArray;
  }

  function createArrayOfObjectsFromString() {
    console.log(items);
    if (items) {
      setStage(1);
      //const lines = newLinesString.split("\n");
      const objects = extractDataFromExisting();
      console.log("objects", objects[0].type);
      console.log("objects", objects);

      for (let item of items) {
        const id = item[0].value;
        const name = item[1].value;

        if (id && name && id !== "" && name !== "") {
          const obj = {
            id: id,
            name: name,
            existing: false,
          };
          objects.push(obj);
        }
      }

      console.log(objects);
      setNewLinesArray(objects);
    }
  }

  function createAndDownloadFile(array) {
    const content = array.join("\n");
    const blob = new Blob([content], { type: "text/plain" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = file.name.split(".")[0] + "ADDED.txt";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }

  function convertToLinesForFinalFile() {
    let finalArray = [];
    let lines = allLines;
    console.log("newLines", newLinesArray)
    for (let i = 0; i < newLinesArray.length; i++) {
      //console.log(newLinesArray[i]);
      let text = `DEVICE${i}=${newLinesArray[i].id}***${defaults.radio_type}***${newLinesArray[i].name}***${defaults.tracking}***${defaults.fleetid}***${defaults.updateratemethod}***${defaults.customrate}***0***0***${defaults.type}******\r`;
      finalArray.push(text);
    }
    lines.splice(4, 0, ...finalArray);
    createAndDownloadFile(lines);
    handleReset();
  }

  return (
    <>
      <div className="itemContainer">
        {stage === 0 ? (
          <>
            <div className="dropBox">
              <FileUploader
                handleChange={handleChange}
                name="file"
                className="dropBox"
              />
            </div>

            {/* <TextField
              sx={{ marginTop: "30px", marginBottom: "20px" }}
              rows={8}
              id="outlined-basic"
              label="New Lines"
              variant="outlined"
              multiline
              onChange={(e) => setNewLinesString(e.target.value)}
            /> */}
            <div
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                display: "flex",
                width: "100%",
                margin: "20px 0px",
                justifyContent: "center",
              }}
              onContextMenu={(e) => e.preventDefault()}
            >
              <Spreadsheet
                data={displayedData}
                className="spreadsheet"
                onChange={handleChangeSpreadsheet}
                columnLabels={displayedColumns}
              />
            </div>

            <BigButton
              disabled={!file}
              onClick={() => {
                createArrayOfObjectsFromString();
              }}
              variant="contained"
            >
              Convert
            </BigButton>
          </>
        ) : null}

        {stage === 1 ? <DataTable data={newLinesArray} /> : null}

        {stage === 1 ? (
          <BigButton onClick={convertToLinesForFinalFile} variant="contained">
            Confirm & Convert To TXT
          </BigButton>
        ) : null}
      </div>
    </>
  );
};

export default Uploader;
